<template>
  <div>
    <Hero />
  </div>
</template>

<script>

import Hero from '@/components/Hero.vue';

export default {
  name: 'HomeView',
  data() {
    return {
    };
  },
  methods: {
  },
  components: {
    Hero,
  },
};

</script>

<style lang="less"></style>
<style lang="less"></style>
