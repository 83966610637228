<template>
  <footer>
    <img src="@/assets/mark.svg" alt="418" />
    <nav>
      <ul>
        <li><router-link to="/about">ABOUT</router-link></li>
        <li><router-link to="/services">SERVICES</router-link></li>
        <li><router-link to="/contact">CONTACT</router-link></li>
      </ul>
      <span>FOUR EIGHTEEN TECHNOLOGIES LLC</span>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style lang="less" scoped>
@link-color: #230075;

@keyframes footer-flip {
  0% {
    left: 0%;
    top: 30px;
    rotate: 210deg;
    opacity: 0;
    filter: blur(5px);
  }

  50% {
    left: 45%;
    top: 20px;
    rotate: 290deg;
    opacity: 1;
    filter: blur(0px);
  }

  100% {
    left: 90%;
    top: 50px;
    rotate: 370deg;
    opacity: 0;
    filter: blur(5px);
  }
}

footer {
  padding: 20px;
  max-width: 1024px;
  overflow: hidden;

  img {
    position: relative;
    width: 30px;
    animation: footer-flip 10s linear infinite
  }

  @media (max-width: 768px) {
    padding: 10px;
  }

  nav {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    img {
      width: 200px;
    }

    ul {
      list-style-type: none;
      padding: 0;

      li {
        display: inline-block;
        margin: 10px;

        margin: 10px;

        a {
          color: @link-color;
          text-decoration: none;

          &:hover {
            color: lighten(@link-color, 10%);
          }
        }
      }
    }
  }
}
</style>
