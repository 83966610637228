<template>
  <div class="animated-background">
  </div>
</template>

<script>
import mark from '@/assets/mark.svg';

export default {
  name: 'AnimatedBackground',
  data() {
    return {
      imageUrl: mark,
    };
  },
  mounted() {
    const numberOfSVGs = 42;
    const svgPaths = [mark];
    const container = this.$el;
    const style = document.createElement('style');

    document.head.appendChild(style);

    for (let i = 0; i < numberOfSVGs; i += 1) {
      const x1 = this.getRandomNumber(0, window.innerWidth);
      const x2 = this.getRandomNumber(0, window.innerWidth);
      const x3 = this.getRandomNumber(0, window.innerWidth);
      const y1 = this.getRandomNumber(0, window.innerHeight);
      const y2 = this.getRandomNumber(0, window.innerHeight);
      const y3 = this.getRandomNumber(0, window.innerHeight);
      const r1 = this.getRandomNumber(0, 420);
      const r2 = this.getRandomNumber(0, 420);
      const r3 = this.getRandomNumber(0, 420);
      const s1 = this.getRandomNumber(10, 100);
      const s2 = this.getRandomNumber(10, 100);
      const s3 = this.getRandomNumber(10, 100);
      const keyframes = `
            @keyframes movement-${i} {
                0% {
                transform: 
                    translate(${x1}px, ${y1}px)
                    rotate(${r1}deg)
                    scale(${s1}, ${s1});
                filter: blur(${Math.abs(s1 - 90) * 0.01}px);
                }
                33% {
                transform: 
                    translate(${x2}px, ${y2}px)
                    rotate(${r2}deg)
                    scale(${s2}, ${s2});
                filter: blur(${Math.abs(s2 - 90) * 0.01}px);
                }
                66% {
                transform: 
                    translate(${x3}px, ${y3}px)
                    rotate(${r3}deg)
                    scale(${s3}, ${s3});
                filter: blur(${Math.abs(s3 - 90) * 0.01}px);
                }
                100% {
                transform: 
                    translate(${x1}px, ${y1}px)
                    rotate(${r1}deg)
                    scale(${s1}, ${s1});
                filter: blur(${Math.abs(s1 - 90) * 0.01}px);
                }
            }`;

      if (style.styleSheet) {
        style.styleSheet.cssText = keyframes;
      } else {
        style.appendChild(document.createTextNode(keyframes));
      }
      const img = document.createElement('img');
      img.src = svgPaths[Math.floor(this.getRandomNumber(0, svgPaths.length))];
      img.style.position = 'absolute';
      img.style.top = 0;
      img.style.left = 0;
      img.style.opacity = `${this.getRandomNumber(0.05, 0.2)}`;
      img.style.width = '1px';
      img.style.height = 'auto';
      img.style.animation = `movement-${i} ${this.getRandomNumber(50, 500)}s ease-in-out infinite`;
      img.style.pointerEvents = 'none';
      container.appendChild(img);
    }
  },
  methods: {
    getRandomNumber(min, max) {
      return Math.random() * (max - min) + min;
    },
  },
};
</script>

<style lang="less" scoped>
.animated-background {
  position: absolute;
  overflow: hidden;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
