<template>
  <main>
    <h2>What does 418 mean?</h2>
    <p>Our name pays homage to the HTTP 418 "I'm a teapot" error, born from an April Fools' joke in
      the tech community. This error humorously indicates that a teapot, despite being requested to
      brew coffee, refuses to do so because it's simply not designed for that task. Similarly, we
      believe in the straightforward and appropriate use of technology. Like the teapot steadfast in
      its purpose, we focus on creating innovative software solutions, ensuring that the right tools
      are used for the right problems. Our approach embodies the clarity and directness of the 418
      error: we prevent the misuse of technology, guiding our clients to effective and purpose-built
      solutions.
    </p>

    <h2>Our Mission</h2>
    At Four Eighteen Technologies, we believe that innovation shouldn't be a daunting task, but a
    delightful journey. Our mission is to blend the seriousness of technology with the
    lightheartedness of creativity, crafting custom software solutions that not only meet the
    highest standards of excellence but also bring joy and ease to the user experience.

    <h2>What We Do</h2>
    From cloud-based services to mobile and web application development, our suite of services is
    way more versatile than a teapot is with beverages.
    We specialize in:
    <ul>
      <li>Custom Software Development: Tailored solutions that fit like a glove.</li>
      <li>Cloud Integration and Migration: Seamless transitions to the cloud, ensuring your data
        flows as smoothly as tea from a pot.</li>
      <li>Mobile and Web Applications: Engaging and intuitive apps that stand out in a crowded
        market.</li>
      <li>Data Analysis and AI: Turning data into insights as refreshing as a cup of tea on a busy
        day.</li>
    </ul>

    <h2>Our Journey</h2>
    <p>Our team has years of experience developing and supporting internal and external custom
      software solutions. We've led and contributed to corporate development teams, uncovering
      patterns and antipatterns that can make or break initiatives and even businesses. We love a
      good problem in search of a solution, rather than waste time on a solution in search of a
      problem. Now, we're eager to channel our expertise to empower you, helping you excel in what
      you do best!</p>

    <h2>Meet the Team</h2>
    <p>At the helm of Four Eighteen Technologies are three passionate leaders who embody our
      mission and drive our success:
    </p>

    <div class="partners">
      <BioCard v-for="(partner, index) in partners" :key="index" :name="partner.name"
        :title="partner.title" :description="partner.description" :imageUrl="partner.imageUrl" />
    </div>
    <p>
      These are the people who make Four Eighteen Technologies what it is—a team dedicated to
      blending innovation with creativity, ensuring every solution we deliver is as effective
      as it is enjoyable.
    </p>

    <h2>Why Choose Us?</h2>
    <p>We're not just developers; we're innovators with a sense of humor and a passion
      for problem-solving.
      Our team is dedicated to providing solutions that are not only effective but also enjoyable to
      use.</p>

    <h2>Let's Brew Something Together</h2>
    Ready to infuse your next project with innovation and a dash of fun?
    Contact Four Eighteen today, and let's start brewing your custom software solution.
  </main>
</template>

<script>
import BioCard from '@/components/Bio.vue';
import chase from '@/assets/pictures/chase.png';
import john from '@/assets/pictures/john.png';
import mason from '@/assets/pictures/mason.png';

export default {
  name: 'AboutView',
  components: {
    BioCard,
  },
  data() {
    return {
      partners: [
        {
          name: 'Chase Sawyer',
          title: 'Director of Operations',
          description: 'Chase is.',
          imageUrl: chase,
        },
        {
          name: 'John Cantrell',
          title: 'Director of Client Strategy',
          description: 'John is.',
          imageUrl: john,
        },
        {
          name: 'Mason Ellis',
          title: 'Director of Engineering',
          description: 'Mason is.',
          imageUrl: mason,
        },
      ],
    };
  },
};

</script>

<style lang="less">
main {
  flex: 1;
  margin: 0 80px;
  font-size: 1.25em;
  line-height: 1.5em;
  text-align: left;
}

.partners {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0;
}

@media (max-width: 768px) {
  main {
    margin: 0 40px;
    font-size: 1em;
  }

  .partners {
    flex-direction: column;
    align-items: center;
  }
}
</style>
