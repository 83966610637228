<template>
  <div class="hero">
    <h1>
      Turning your
      <span class="orange">challenges</span>
      <br />
      into
      <span class="green">innovations</span>
      with custom software <span class="blue">solutions</span>
    </h1>
  </div>
</template>

<script>
export default {
  name: 'HeroComponent',
  data() {
    return {
      isMenuVisible: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
    },
  },
};
</script>

<style lang="less" scoped>
.hero {
  padding-left: 100px;
  font-family: 'Helvetica Neue', sans-serif;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  animation: fadeIn 2s ease-in-out;

  margin: auto;

  h1 {
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    color: #555;
    font-weight: 600;
    font-size: 7em;

    line-height: 1em;
    text-align: left;
    transform: rotate(0.418deg);

    .orange {
      color: #ac675b;
    }

    .blue {
      color: #7e7fab
    }

    .green {
      color: #49876c;
    }

    @media (max-width: 768px) {
      padding: 20px;
      font-size: 3em;
    }

  }

  @media (max-width: 768px) {
    padding: 10px;
  }
}

@header-bg-color: #fff;
@link-color: #000;

p {
  max-width: 1000px;
  font-weight: 600;
  font-size: 2em;
  color: black;
  transform: rotate(0.418deg);
}
</style>
