<template>
  <div class="bio-card">
    <img :src="imageUrl" alt="Profile Image" class="bio-card__image" />
    <h2 class="bio-card__name">{{ name }}</h2>
    <h4 class="bio-card__title">{{ title }}</h4>
  </div>
</template>

<script>
export default {
  name: 'BioCard',
  props: {
    imageUrl: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.bio-card {
  padding: 16px;
  max-width: 300px;
  text-align: center;
}

.bio-card__image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 16px;
}

.bio-card__name {
  font-size: 1.5em;
  margin: 0;
  color: #333;
}

.bio-card__title {
  font-size: .8em;
  margin: 8px 0;
  color: #666;
}

.bio-card__description {
  font-size: 1em;
  color: #555;
}

@media (max-width: 768px) {
  .bio-card__image {
  width: 300px;
  height: 300px;
  }
}
</style>
