<template>
  <div class="content-container">
    <Header />
    <router-view />
    <Footer />
  </div>
  <AnimatedBackground />
</template>

<script>
import Header from '@/components/Header.vue';
import AnimatedBackground from './components/AnimatedBackground.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    AnimatedBackground,
    Header,
    Footer,
  },
};

</script>

<style lang="less">
html,
body {

  html,
  body {
    height: 100%;
    width: 100%;
    margin: auto;
    padding: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .content-container {
    z-index: 11;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 1024px;
    min-height: 100vh;
    min-height: 100vh;
    margin: auto;
  }
}
</style>
