<template>
  <main>
    <h2>Streamlining Logistics with Turvo API Integrations</h2>
    <p>
      Our expertise in integrating Turvo seamlessly with a variety of external systems and data
      sources sets us apart, ensuring that your business information remains synchronized and
      current. Our integrations are crafted to streamline operations, bolster data accuracy, and
      enhance service delivery—paving the way for a more agile and customer-centric business model.
    </p>
    <h3>Comprehensive Integration Capabilities</h3>
    <p>We facilitate a wide range of data sources, from CSV
      to modern web APIs, enabling robust connections for thorough analytics and informed
      decision-making.
    </p>
    <h3>
      Automated Data Synchronization</h3>
    <p>Our automated processes sync records between
      Turvo and external systems, maintaining current data across platforms and minimizing manual
      entry errors.
    </p>
    <h3>
      Enhanced Data Accessibility</h3>
    <p>We ensure real-time updates of external systems with Turvo data,
      offering timely insights for customer service and strategic decisions. </p>
    <h3>
      Data Transparency</h3>
    <p>Leveraging Turvo's webhooks, we build data lakes that provide instant
      visibility into shipments, customer interactions, and carrier details, thus improving
      reporting and market agility.
    </p>
    <h3>
      Real-Time Alerts and Responses</h3>
    <p>Our real-time alerts enable clients to quickly react to
      developments, optimizing operational efficiency and responsiveness.
    </p>

    <p>
      Commit to elevating your logistics operations with our expertise in Turvo's API—automating
      data flows, streamlining system integration, and unlocking valuable insights. Partner with us
      to empower logistics leaders to navigate today's business complexities with precision and
      speed. Let's transform your logistics into a seamless, efficient, and insight-driven
      powerhouse.
    </p>
  </main>
</template>

<script>

export default {
  name: 'ServicesView',
};

</script>

<style lang="less">
main {
  flex: 1;
  margin: 0 80px;
  font-size: 1.25em;
  line-height: 1.5em;
  text-align: left;
}

@media (max-width: 768px) {
  main {
    margin: 0 40px;
    font-size: 1em;
  }
}
</style>
