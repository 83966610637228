<template>
  <main>
    <h2>Our Services</h2>
    <p>
      At Four Eighteen Technologies, we offer a wide range of services designed to transform your
      digital landscape. Our team of experts combines technical prowess with creative thinking to
      deliver solutions that not only solve complex problems but also inspire innovation.
    </p>

    <h2>Custom Software Development</h2>
    <p>
      Need something unique? Our custom software development service is the perfect blend of
      technical expertise and artistic expression. We craft software that’s tailored to your
      business needs, ensuring a perfect fit for your operations.
    </p>

    <h2>Cloud Integration and Migration</h2>
    <p>
      Elevate your business to new heights with our cloud integration and migration services. We
      provide seamless transition strategies for moving to the cloud, enabling you to leverage
      scalable, flexible, and cost-effective solutions.
    </p>

    <h2>Mobile and Web Applications</h2>
    <p>
      In today’s mobile-first world, we design and develop mobile and web applications that offer
      compelling user experiences, are feature-rich, and run seamlessly across all devices. Our
      apps are designed to engage your customers and enhance your digital presence.
    </p>

    <h2>Data Analysis and AI</h2>
    <p>
      Unleash the power of your data with our data analysis and artificial intelligence services.
      We help you make sense of your data to drive decision-making and create intelligent systems
      that improve over time, ensuring your business stays ahead of the curve.
    </p>

    <h2>Why Us?</h2>
    <p>
      Our commitment to innovation, quality, and customer satisfaction sets us apart in the tech
      industry. At Four Eighteen Technologies, we’re not just building software; we’re crafting
      experiences that help businesses thrive in a digital era.
    </p>

    <h2>Get Started with Us</h2>
    <p>
      Ready to take your business to the next level? Contact us today to discover how our services
      can empower your company’s future.
    </p>
  </main>
</template>

<script>

export default {
  name: 'ServicesView',
};

</script>

<style lang="less">
main {
  flex: 1;
  margin: 0 80px;
  font-size: 1.25em;
  line-height: 1.5em;
  text-align: left;
}

@media (max-width: 768px) {
  main {
    margin: 0 40px;
    font-size: 1em;
  }
}
</style>
