<template>
  <header :class="{ 'menu-visible': isMenuVisible, 'menu-hidden': !isMenuVisible }">
    <router-link to="/">
      <img src="@/assets/logo.svg" alt="Four Eighteen Technologies">
    </router-link>
    <nav :class="{ visible: isMenuVisible }">
      <router-link to="/about">ABOUT</router-link>
      <router-link to="/services">SERVICES</router-link>
      <router-link to="/contact">CONTACT</router-link>
    </nav>
    <button class="hamburger" :class="{ rotated: isMenuVisible }" @click="toggleMenu">☰</button>
    <button class="hamburger" :class="{ rotated: isMenuVisible }" @click="toggleMenu">☰</button>
  </header>
</template>

<script>
import { ref, watchEffect } from 'vue';

export default {
  setup() {
    const isMenuVisible = ref(false);
    const screenWidth = ref(window.innerWidth);

    function toggleMenu() {
      if (isMenuVisible.value) {
        isMenuVisible.value = false;
      } else {
        isMenuVisible.value = true;
      }
    }

    watchEffect(() => {
      screenWidth.value = window.innerWidth;
      if (screenWidth.value > 768) {
        isMenuVisible.value = true;
      } else {
        isMenuVisible.value = false;
      }
    });

    window.addEventListener('resize', () => {
      screenWidth.value = window.innerWidth;
    });

    return {
      isMenuVisible, toggleMenu,
    };
  },
  name: 'HeaderComponent',
};
</script>

<style lang="less" scoped>
header {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;

  img {
    padding: 10px;
    width: 250px;
    width: 250px;
  }

  nav {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: end;

    a {
      color: #57587b;
      text-decoration: none;
      margin: 0 20px;
      font-weight: 700;
      font-size: 1.4em;
    }
  }

  .hamburger {
    display: none;
  }
}

@media (max-width: 768px) {
  header {
    -webkit-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "logo hamburger"
      "blank nav-menu";

    a {
      justify-self: start;

      img {
        grid-area: logo;
        width: 150px;
      }
    }

    nav {
      display: flex;
      grid-area: nav-menu;
      visibility: hidden;
      max-height: 0;
      overflow: hidden;
      flex-direction: column;
      align-items: flex-end;
      font-size: 1.5em;
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
      opacity: 0;
      -webkit-transition: opacity .3s ease-in-out .1s, transform .3s ease-in-out .1s,
        visibility 0s linear .4s, max-height .3s ease-in-out .1s;
      transition: opacity .3s ease-in-out .1s, transform .3s ease-in-out .1s,
        visibility 0s linear .4s, max-height .3s ease-in-out .1s;

      a {
        margin-bottom: 10px;
      }
    }

    nav.visible {
      display: flex;
      opacity: 1;
      visibility: visible;
      max-height: 500px;
      -webkit-transform: translateY(0);
      transform: translateY(0);
      -webkit-transition: opacity .3s ease-in-out .1s, transform .3s ease-in-out .1s,
        visibility 0s linear 0s, max-height .3s ease-in-out .1s;
      transition: opacity .3s ease-in-out .1s, transform .3s ease-in-out .1s,
        visibility 0s linear 0s, max-height .3s ease-in-out .1s;
    }

    .hamburger {
      border: none;
      outline: none;
      background-color: transparent;
      margin-left: auto;
      grid-area: hamburger;
      display: block;
      font-size: 2em;
      margin-right: 20px;
      -webkit-transition: transform .5s ease-in-out;
      transition: transform .5s ease-in-out;

      &.rotated {
        -webkit-transform: rotate(7deg);
        transform: rotate(7deg);
      }
    }
  }

  header.menu-visible {
    max-height: 100vh;
  }

  header.menu-hidden {
    max-height: 103px;
  }
}
</style>
