<template>
  <main>
    <h2>Contact Us</h2>
    <p>Interested in our services or need a consultation? Email us at
      <a href="mailto:{{contactEmail}}">{{ contactEmail }}</a>,
      and our team will get back to you as soon as possible.
    </p>
  </main>
</template>

<script>
export default {
  name: 'ContactView',
  data() {
    return {
      contactEmail: 'contact@foureighteen.com',
    };
  },
  methods: {
    submitForm() {
      console.log('Form submitted:', this.form);
      // Here you would handle the form submission, e.g., send data to an API
    },
  },
};
</script>

<style lang="less">
main {
  flex: 1;
  margin: 0 80px;
  font-size: 1.25em;
  line-height: 1.5em;
  text-align: left;
}

@media (max-width: 768px) {
  main {
    margin: 0 40px;
    font-size: 1em;
  }
}
</style>
